@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "@core/scss/angular/libs/datatables.component.scss";
@import "@core/scss/base/pages/ui-feather.scss";
@import "@core/scss/angular/libs/toastr.component.scss";
@import "@core/scss/angular/libs/select.component.scss";
@import "@core/scss/base/bootstrap-extended/include";
@import "@core/scss/base/pages/page-auth.scss";
@import "@core/scss/base/pages/page-misc.scss";
@import "@core/scss/angular/libs/date-time-picker.component.scss";
@import "@core/scss/angular/libs/form-wizard.component.scss";
@import "@core/scss/angular/libs/file-uploader.component.scss";
@import "@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/angular/libs/flatpickr.component.scss";

.datatable-scroll {
  width: auto !important;
}

.toast-body {
  background-color: #fff !important;
}

.ng-select,
.ng-select div,
.ng-select input,
.ng-select span {
  font-size: 1rem !important;
}

.alert {
  font-size: 1rem !important;
  margin-bottom: 0px !important;
}

.popover { max-width: 100% !important;}

.fw-bold {
  font-weight: bold !important;
}

.ngx-datatable .datatable-body {
  overflow: hidden !important;
}


.ngx-datatable .datatable-body .datatable-body-row > div {
  align-items: left !important;
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  text-align: left;
}

.my-custom-cell-global {  
  text-align: left;
  
}

.ngx-datatable.bootstrap .datatable-body .datatable-header-cell .datatable-body-row .datatable-body-cell {
  text-align: left;
  vertical-align: top;  
  align-items: left !important;
}